define("ember-svg-jar/inlined/linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.714 0H2.28C1.02 0 0 1.036 0 2.307v27.386C0 30.964 1.021 32 2.279 32h27.435C30.971 32 32 30.964 32 29.693V2.307C32 1.036 30.971 0 29.714 0zM9.671 27.429H4.93V12.157h4.75V27.43H9.67zM7.3 10.07a2.751 2.751 0 010-5.5 2.756 2.756 0 012.75 2.75 2.747 2.747 0 01-2.75 2.75zM27.45 27.43h-4.743V20c0-1.771-.036-4.05-2.464-4.05-2.472 0-2.85 1.929-2.85 3.921v7.558H12.65V12.157h4.55v2.086h.064c.636-1.2 2.186-2.464 4.493-2.464 4.8 0 5.693 3.164 5.693 7.278v8.372z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});