define("ember-svg-jar/inlined/logo-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.404 20.353L10.808 2H0l16 27.713 5.404-9.36z\" fill=\"#202020\" fill-opacity=\".75\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.339 2l5.33 9.233L32 2 21.405 20.353 10.808 2h10.53z\" fill=\"#000\" fill-opacity=\".4\"/><path d=\"M26.67 11.233L21.34 2H32l-5.33 9.233z\" fill=\"#000\" fill-opacity=\".2\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});