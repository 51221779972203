define("ember-svg-jar/inlined/x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.046.705L6.341 0 3.523 2.818.705 0 0 .705l2.818 2.818L0 6.341l.705.705 2.818-2.818 2.818 2.818.705-.705-2.818-2.818L7.046.705z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 8 8"
    }
  };
});