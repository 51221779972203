define("ember-svg-jar/inlined/search-illustration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><circle cx=\"40\" cy=\"40\" r=\"36.667\" fill=\"#E5EBF0\"/><path d=\"M60.983 18.701a30.067 30.067 0 00-35.465-5.097 1.66 1.66 0 001.59 2.916 26.721 26.721 0 0131.527 4.529 1.648 1.648 0 001.174.486 1.66 1.66 0 001.174-2.834zM22.79 17.278a1.66 1.66 0 00-2.34-.182 30.59 30.59 0 00-1.736 1.604 1.66 1.66 0 00-.487 1.178 1.66 1.66 0 001.663 1.657 1.648 1.648 0 001.174-.488 27.405 27.405 0 011.544-1.428 1.66 1.66 0 00.182-2.34z\" fill=\"#F8F9FA\"/><path d=\"M99.514 87.775l.005-.005-26.272-26.267c11.963-18.457 6.698-43.118-11.76-55.081C43.03-5.541 18.37-.276 6.406 18.182A39.826 39.826 0 000 39.836c-.054 21.968 17.71 39.82 39.678 39.875a39.78 39.78 0 0021.848-6.472l26.26 26.264a1.66 1.66 0 002.347 0l9.381-9.38a1.66 1.66 0 000-2.348zM60.795 69.758c-14.509 10.201-34.256 8.47-46.768-4.1C-.24 51.394-.241 28.265 14.023 14 28.287-.266 51.414-.268 65.68 13.996c12.537 12.535 14.263 32.262 4.094 46.783a36.803 36.803 0 01-8.979 8.98zm28.17 26.224L64.286 71.304c.267-.207.52-.433.782-.647.224-.185.448-.367.664-.556a40.288 40.288 0 002.284-2.094 39.157 39.157 0 002.094-2.285c.19-.22.372-.445.556-.664.214-.262.44-.515.648-.782L95.997 88.95l-7.033 7.033z\" fill=\"#809099\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h100v100H0z\"/></clipPath></defs>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});