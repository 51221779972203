define("ember-svg-jar/inlined/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.852 6.85l-3.43-2.706a.61.61 0 00-.388-.15.599.599 0 00-.394.156L12.16 20.187s-4.906-4.718-5.044-4.856c-.137-.137-.319-.369-.594-.369s-.4.194-.543.338c-.107.112-1.857 1.95-2.72 2.862-.05.057-.08.088-.124.132a.616.616 0 00-.125.356.6.6 0 00.125.356l.175.163s8.706 8.362 8.85 8.506c.144.144.319.325.575.325.25 0 .456-.269.575-.388l15.569-20a.62.62 0 00.125-.362.643.643 0 00-.15-.4z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});