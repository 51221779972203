define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.125 5.143c0-.355.32-.643.716-.643h14.318c.395 0 .716.288.716.643 0 .355-.32.643-.716.643H1.841c-.396 0-.716-.288-.716-.643zM2.557 9c0-.355.32-.643.716-.643h11.454c.396 0 .716.288.716.643 0 .355-.32.643-.716.643H3.273c-.396 0-.716-.288-.716-.643zm3.58 3.214c-.396 0-.717.288-.717.643 0 .355.321.643.716.643h5.728c.395 0 .715-.288.715-.643 0-.355-.32-.643-.715-.643H6.136z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 18"
    }
  };
});